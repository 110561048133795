export default {
  data() {
    return {
      selectingRouteInfo: {},
      selectedRouteInfoList: [],
      routeTempVal: "",
      collectCompanyId: "",
      disposalCompanyId: "",
      routeOptions: [],
      wdsFilePath: "",
      jwnetItemTypeName: "",

      // 一時的に使用用
      // 絞り込み後のルート（画面遷移時はAPI返り値全件を代入）
      narrowedRoutesList: [],
    };
  },
  methods: {
    // 廃棄物種類のchange処理
    jwnetItemChangeHandler() {
      //ルートをリセット
      this.form.routeList = [];
      this.form.entrustIds = null;
      this.selectedRouteInfoList = [];
      this.selectedRoute = [];
    },

    // ルート選択時
    onChangeRouteHandler() {
      if (this.form.routeList.routeId) {
        this.selectedRouteInfoList = [];
        for (let id in this.form.routeList.routeId) {
          const route = this.narrowedRoutesList.filter(
              (r) => r.routeId === this.form.routeList.routeId[id]
          )[0];
          this.selectingRouteInfo.routeId = route.routeId;
          this.selectingRouteInfo.routeName = route.routeName;
          this.selectingRouteInfo.wasteName = route.wasteNameInfo.name;
          this.selectingRouteInfo.generateStore = route.generateStoreInfo.name;
          this.selectingRouteInfo.collectCompanyList = route.collectCompanyList;
          this.selectingRouteInfo.disposalCompanyName = route.disposalCompany.name;
          this.selectingRouteInfo.disposalSiteAddress = route.disposalSiteAddressInfo.zipCode + " " + route.disposalSiteAddressInfo.prefecturesName
                      + route.disposalSiteAddressInfo.address + route.disposalSiteAddressInfo.buildingName;
          this.selectingRouteInfo.entrustId = route.entrustId;
          this.selectedRouteInfoList.push(this.selectingRouteInfo);
          this.form.routeList.push({id: route.routeId});
          this.selectingRouteInfo = {};
        }
      }
      var entrustIdsList = this.selectedRouteInfoList.map((r) => r.entrustId);
      this.form.entrustIds = entrustIdsList.filter(
          (val, index) => entrustIdsList.indexOf(val) == index
      ).join(",");
    },
  },
};
