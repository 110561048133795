export default {
  data() {
    return {
      form: {
        priorConsultationNo: null,
        targetMunicipalityName: null,
        generateCompany: {
          id: null,
          name: null,
        },
        jwnetItemType: {
          id: null,
          name: null,
        },
        routeList: [],
        limitQuantity: null,
        priorConsultationStartDate: null,
        priorConsultationEndDate: null,

        countTermList: [
          {
            countTermStartDate: null,
            countTermEndDate: null,
            alertValue: null,
          }
        ],
        entrustIds: null,
        remarks: null,

        filePath: null,
        fileName: null,
        }
    };
  },
};
